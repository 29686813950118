import getUrlParameter from '../get-url-parameter';

(function ($, getUrlParameter) {
  "use strict";

  var msClkId = getUrlParameter('msclkid');
  var waitCounter = 0;
  var msClkIdElem = $('input[name="msclkid"]');

  if (msClkId) {

    console.log('msclkid parameter detected');
    msClkIdElem.val(msClkId);

  } else {

    var interval = window.setInterval(function () {

      waitCounter++;
      console.log('checking for msclkid...');

      if (window.uetq) {

        msClkId = window.uetq.msClkId;
        console.log('msclkid found: ', msClkId);

      }

      if (msClkId || waitCounter >= 5) {

        msClkIdElem.val(msClkId);
        clearInterval(interval);

      }

    }, 1000);

  }

})(jQuery, getUrlParameter);