
(function ($) {
  'use strict';

    // if deluxe available then set it (premium for Holidays)
    // if ($('#optionThree').length) {

      // $('input:radio[id=radio6]').prop('checked', true);
      // $('#optionThree').addClass('checked');

    //} else if ($('#optionTwo').length) {
    if ($('#optionTwo').length) {
      $('input:radio[id=radio5]').prop('checked', true);
      $('#optionTwo').addClass('checked');
      console.log('optionTwo',$('#optionTwo').find('.product-form__pricepicker__option__price'));
      // update the klarna installment price
      let installmentPrice = (parseFloat($('#optionTwo').find('.product-form__pricepicker__option__price').text().replace(/[$C]/g, '')) / 4).toFixed(2);
      console.log('installmentPrice', installmentPrice);
      let klarnaInstallmentsContainer = $('.klarna-installment-price');
      console.log('klarnaInstallmentsContainer', klarnaInstallmentsContainer);
      klarnaInstallmentsContainer.html(' $' + installmentPrice);
      
    } else {
  
      //set Standard as default select
      $('input:radio[id=radio4]').prop('checked', true);
      $('#optionOne').addClass('checked');
      console.log('optionOne', $('#optionOne'));
      // update the klarna installment price
      let installmentPrice = (parseFloat($('#optionOne').find('.product-form__pricepicker__option__price').text().replace(/[$C]/g, '')) / 4).toFixed(2);
      let klarnaInstallmentsContainer = $('.klarna-installment-price');
      klarnaInstallmentsContainer.html(' $' + installmentPrice);
  
    }
  

  $('#optionOne, #optionTwo, #optionThree').click(function () {

    var pickthis = $(this).data('pick');

    $('input:radio#' + pickthis).prop('checked', true);
    $(this).addClass('checked');
    $('li').not($(this)).removeClass('checked');
    console.log('this', $(this));
    // update the klarna installment price
    let installmentPrice = (parseFloat($(this).find('.product-form__pricepicker__option__price').text().replace(/[$C]/g, '')) / 4).toFixed(2);
    let klarnaInstallmentsContainer = $('.klarna-installment-price');
    klarnaInstallmentsContainer.html('$' + installmentPrice);
  });

  $('#o').val($('#holiday_occasion').val());

  $('a[data-reveal-id]').click(function () {
    setTimeout(() => {
      window.lazyLoader.revalidate();
    }, 300);
  });

  // Format decimal places
  var amount = $('span.price').html();
  var newamount = parseFloat(amount).toFixed(2);
  $('span.price').html(newamount);

  window.createSticky($('#stickyHeaderFcPlus'), 200);

})(jQuery);