(function ($) {

  // both assurances hover if one hovers
  $('.assurance').hover(
    function () {
      $(this).children().toggleClass('solid_opacity');
    }
  );

  // Print this
  $('#productPrint').click(function () {
    window.print();
  });

  // Take any external link, add target=_blank and rel="nofollow"
  // this is for the testimonial block links
  $.expr[':'].external = function (obj) {
    return !obj.href.match(/^mailto\:/) && obj.hostname !== location.hostname && !obj.href.match(/^javascript\:/) && !obj.href.match(/^$/);
  };

  $('a:external').attr('target', '_blank');
  $('a:external').attr('rel', 'nofollow');

  // Fill in the appropriate message
  var message_orig = $('li.select_size.checked').data('message');
  $('#priceDescription').html(message_orig);

  

  // hide/show testimonials if empty
  function isEmpty(el) {
    return !$.trim(el.html());
  }

  if (isEmpty($('#empty_feedback'))) {

    $('#customer_feedback').hide();

    $('#also_viewed').hide();

    $('#empty_feedback_fallback').removeClass('hide');

    $('#empty_feedback_fallback .large-3:nth-of-type(3),#empty_feedback_fallback .large-3:nth-of-type(4)').hide();

    $('#empty_feedback_fallback .large-3:nth-of-type(1),#empty_feedback_fallback .large-3:nth-of-type(2)').removeClass('large-3 small-3').addClass('large-6');

    $('#empty_feedback_fallback .large-3:nth-of-type(1) div,#empty_feedback_fallback .large-3:nth-of-type(2) div').addClass('text-center');

  } else {

    $('#empty_feedback_fallback').addClass('hide');

    $('#customer_feedback').show();

    $('#also_viewed').show();

  }

  $('.description span').attr('style', '');

  // If gambit
  function sticky_relocate() {
    var window_top = $(window).scrollTop();
    var footer_top = $("footer").offset().top;
    var div_top = 0;
    var div_height = $("#sticky").height();

    var padding = 200;

    if (window_top + div_height > footer_top - padding)
      $('#sticky').removeClass('stick');
    else if (window_top > div_top) {
      $('#sticky').addClass('stick');
    } else {
      $('#sticky').removeClass('stick');
    }
  }

  if (window.storeSettings && window.storeSettings.theme_id === 7) {

    $(window).scroll(sticky_relocate);
    sticky_relocate();

  }

})(jQuery);