jQuery(document).ready(function () {
  "use strict";

  if (window.ga) {

    window.ga(function () {

      var linkerParam = window.ga.getByName('globalTracker').get('linkerParam');

      // linkerParam may contain both _ga and _gac (adwords), possibly others?
      // Split into a kvp map
      var linkerParams = linkerParam && linkerParam.split('&')
          // Split each kvp into an object property/value in a new object
          .reduce(function (a, e) {
            var keyValue = e.split('=');
            a[keyValue[0]] = keyValue[1];
            return a;
          }, {});

      if (!linkerParams) {

        return;

      }

      if (linkerParams._ga) {

        $('input[name="_ga"]').val(linkerParams._ga);

        document.getElementById("ga_tracker").disabled = false;

      }

      if (linkerParams._gac) {

        $('input[name="_gac"]').val(linkerParams._gac);

        document.getElementById("gac_tracker").disabled = false;

      }

    });

  }
});