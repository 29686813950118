(function ($) {
  'use strict';

 

  var productBase64EncodedJsonElement = document.getElementById('product_json');
  var productBase64EncodedJson = productBase64EncodedJsonElement && productBase64EncodedJsonElement.value;
  var product = productBase64EncodedJson ? JSON.parse(atob(productBase64EncodedJson)) : [];
  let no_delivery_dates;
  let no_pickup_dates;
  let temp_theme_dates;
  if(!product.is_virtual){ 
      temp_theme_dates = JSON.parse(atob($('#temp_theme_dates').val()));
    if (!temp_theme_dates) {
      console.warn('No temp theme dates set');
    }
    }
  function initPlaceOrderHandler() {
    // Prevent form from submitting if a date hasn't been selected 
    $('#placeOrder').submit(function () {

      if ($('#o_type').val() === 'localdelivery' && $('#hidden_delivery_date').val() === '' ||
        $('#o_type').val() === 'pickup' && $('#hidden_pickup_date').val() === '') {

        // Open up the datepicker
        console.warn('No date selected'); 
        return false;

      }

      // if (!$('#hidden_pickup_date').val() &&
      //     $('#o_type').val() === 'localdelivery') {

      //     $('#hidden_pickup_date').val($('#hidden_delivery_date').val());

      // }

    });
  }

  function loadDates(elem) {

    var datesClosed = $.ajax({
      url: '/storefronts/datesClosed?tempThemeDate=' + $('#today_date').val(),
      dataType: 'json'
    });

    datesClosed.done(function (response) {
      no_delivery_dates = response.no_delivery_dates;
      no_pickup_dates = response.no_pickup_dates;

      // refresh the cal dates in case any
      // info changed while customer on this page
      // target both elements
      if (elem) {
        $(elem).datepicker('refresh');
        $('#ui-datepicker-div').attr('notranslate', '');
      }

    });

  }

  function init() {
    // Clicking the date buttons will add the date to the input field
    $('#delivery_datepicker_input').val('');
    $('#pickup_datepicker_input').val('');

    // When you click on a  date button (calendar)
    $('.dateLink').click(function () {

      var type = $(this).data('ordertype');

      // If grayedOut (disabled) do nothing
      if ($(this).hasClass('grayedOut')) {
        return;
      }

      // Add active
      $(this).addClass('active');

      // Remove active class from other links
      $('#' + type + '_datepicker .dateLink, #' + type + '_datepicker button')
        .not(this)
        .removeClass('active');

      // Fill hidden date field
      var dateData = $(this).data('thisdate');
      $('#hidden_' + type + '_date').val(dateData);

      // Reset calendar button trigger if it's not the one being clicked
      if ($(this).hasClass('date-picker-non-calendar')) {

        $('#' + type + '_datepicker_input').val('');

      }

      return type === 'delivery' ? localStorage.setItem('deliveryDate', dateData) : localStorage.setItem('pickupDate', dateData);

    });

    needItTodayClickHandler();

    initPlaceOrderHandler();
  }

  function needItTodayClickHandler() { 

    $('.need-it-today').click(function () {
      
      $('#pickupTab').click();

    });
  }

  // compare the date with the array
  function deliveryDateIsSelectable(date) {

    return dateIsSelectable(date, 'delivery');

  }

  function pickupDateIsSelectable(date) {

    return dateIsSelectable(date, 'pickup');

  }

  function dateIsSelectable(date, type) {

    // sugarjs date compare
    // extra check for today
    // use sugarjs array function none() to compare with closedDates
    // array from ajax call and determine if the date is a closed date
    var formattedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];

    var closed;

    if (type === 'delivery') {

      closed = no_delivery_dates.find(ndd => ndd === formattedDate);

    } else {

      closed = no_pickup_dates.find(ndd => ndd === formattedDate);

    }

    // temp_theme_dates already defined in view 
    var linkLength = $(type + '_dates_container .dateLink').length;

    if(!product.is_virtual){ 
    var open = temp_theme_dates.slice(linkLength).find(ndd => ndd.date === formattedDate);
    }
    // check the setting in the nodeliverytoday var

    // [0]: true/false indicating whether or not this date is selectable
    // [1]: a CSS class name to add to the date's cell or "" for the default presentation
    // [2]: an optional popup tooltip for this date
    return [
      open && !closed, ''
    ];

  }

  function initDatePickers() {

    // target both elements
    $('#delivery_datepicker_input').datepicker({
      showOn: 'button',
      buttonText: 'More Dates',
      minDate: '0d',
      maxDate: '+30d',
      beforeShowDay: deliveryDateIsSelectable,
      showOtherMonths: true,
      selectOtherMonths: true,
      showAnim: 'fold',
      beforeShow: loadDates,
      altField: '#hidden_delivery_date',
      altFormat: 'yy-mm-dd',
      onSelect: deliveryDateSelect
    });

    $('#pickup_datepicker_input').datepicker({
      showOn: 'button',
      buttonText: 'More Dates',
      minDate: '0d',
      maxDate: '+30d',
      beforeShowDay: pickupDateIsSelectable,
      showOtherMonths: true,
      selectOtherMonths: true,
      showAnim: 'fold',
      beforeShow: loadDates,
      altField: '#hidden_pickup_date',
      altFormat: 'yy-mm-dd',
      onSelect: pickupDateSelect
    });
  }

  function pickupDateSelect(date) {

    dateSelect(date, 'pickup');

  }

  function deliveryDateSelect(date) {

    dateSelect(date, 'delivery');

  }

  function dateSelect(date, type) {

    localStorage.setItem(type === 'delivery' ? 'deliveryDate' : 'pickupDate', date);

    // Remove active class from all links
    $.each($('#' + type + '_datepicker .dateLink'), function (index, value) {

      if ($(value).hasClass('active')) {

        $(value).removeClass('active');

      }

    });

    // Replace value with date, add active class
    // Remove 20 from 20XX to make short enough to not wrap in container
    var datestring = date;

    if (date.indexOf('/') !== -1) {

      datestring = date.split('/');
      datestring[2] = datestring[2].replace('20', '');
      datestring = datestring.join('/');

    }

    $('#' + type + '_datepicker .ui-datepicker-trigger')
      .html(datestring)
      .addClass('active');

  }

  function initVirtualSubmitHandler() {

    // Prevent form from submitting if a date hasn't been selected 
    $('#placeOrder').submit(function () {

      $('#o_type').val('localdelivery');
      $('#hidden_delivery_date').val('2038-01-19');

      sessionStorage.removeItem('lv.useCode');

    });
  }

  if (product.is_virtual) {
    $('.orderTimer').hide();
    $('#hidden_delivery_date').val('');
    initVirtualSubmitHandler();
  } else {
    loadDates();
    init();
    initDatePickers();
  }

})(jQuery);